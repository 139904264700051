<template>
  <div class="login">
    <form ref="authForm" class="login-form" @submit.prevent="onSubmitForm">
      <h1 class="auth-title">Log in</h1>
      <p class="auth-subtext">
        Enter your e-mail and password or use social media to log in to Resume
        Perfect
      </p>

      <AppField
        type="email"
        label="Enter your e-mail"
        required
        :isInvalid="$v.email.$error"
        @input="setField($event, 'email')"
      >
        <template v-slot:error>
          <p v-if="!$v.email.required">This field is required</p>
          <p v-if="!$v.email.email">Incorrect email format</p>
        </template>
      </AppField>

      <AppField
        type="password"
        label="Password"
        required
        :isInvalid="$v.password.$error"
        @input="setField($event, 'password')"
      >
        <template v-slot:error>
          <p v-if="!$v.password.required">This field is required</p>
          <p v-if="!$v.password.minLength">
            Password must have at least
            {{ $v.password.$params.minLength.min }} symbols.
          </p>
        </template>
      </AppField>

      <p class="forgot-subtext">
        Forgot Password?
        <router-link :to="ROUT_PATH.AUTH.RESET_PASSWORD"
          >Click Here
        </router-link>
      </p>

      <AppButton
        title="Log In"
        class="form-btn"
        className="primary"
        type="submit"
        :pending="pending"
      />
    </form>

    <p class="auth-form-subtext">Easy login with your social profile</p>

    <SocialAuthButtons />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { email, minLength, required } from "vuelidate/lib/validators";

import {
  ROUT_PATH,
  SUBSCRIBE_STEPS_NAMES,
  USER_ACCOUNT_NAMES,
} from "@/helpers/rout-constants";
import CompletedTemplate from "@/helpers/completed-template-hash";
import { authMixin } from "@/mixins/AuthMixin";
import SocialAuthButtons from "@/components/auth/SocialAuthButtons";
import { mixpanelMixin } from "@/mixins/MixPanelMixin";
import * as FullStory from "@fullstory/browser";

export default {
  name: "Login",
  mixins: [authMixin, mixpanelMixin],
  components: {
    SocialAuthButtons,
  },

  computed: {
    ...mapGetters({
      isLoggedIn: "auth/isLoggedIn",
      isSubscribed: "user/isSubscribed",
    }),
  },

  data() {
    return {
      email: "",
      password: "",
      ROUT_PATH,
    };
  },

  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
      minLength: minLength(8),
    },
  },

  async created() {
    if (this.isLoggedIn) {
      if (CompletedTemplate.get() || this.$route.params.planId) {
        await this.$router.push(ROUT_PATH.SUBSCRIBE_STEPS.SUBSCRIBE_PLANS);
      } else {
        await this.$router.push(ROUT_PATH.USER_DASHBOARD);
      }
    }
    this.mixpanelTrack("V2-LOGIN");
  },

  methods: {
    async onSubmitForm() {
      const payload = {
        email: this.email,
        password: this.password,
      };

      try {
        await this.sendData(payload, "auth/login");
      } catch (e) {
        return;
      }

      FullStory.setUserVars({
        email: this.email,
      });

      this.mixpanelTrack("V2-SIGNIN", {
        email: this.email,
      });

      if (CompletedTemplate.get()) {
        if (CompletedTemplate.get().type == "resume") {
          await this.$store.dispatch("resumeCreation/bindResumeToUser");
        }
        if (CompletedTemplate.get().type == "letter") {
          await this.$store.dispatch("coverLetterCreation/bindLetterToUser");
        }
      }

      if (CompletedTemplate.get()) {
        if (this.isSubscribed) {
          const name =
            CompletedTemplate.get().type === "resume"
              ? USER_ACCOUNT_NAMES.USER_RESUME_DETAILS
              : USER_ACCOUNT_NAMES.USER_LETTER_DETAILS;

          await this.$router.push({
            name,
            params: { id: CompletedTemplate.get().hash },
            query: { uncompletedTemplate: CompletedTemplate.get().hash },
          });

          return;
        }

        await this.$router.push({
          name: SUBSCRIBE_STEPS_NAMES.SUBSCRIBE_PLANS,
        });

        return;
      }

      if (this.$route.params.planId) {
        await this.$router.push({
          name: SUBSCRIBE_STEPS_NAMES.SUBSCRIBE_PLANS,
        });

        return;
      }

      await this.$router.push({
        name: USER_ACCOUNT_NAMES.USER_DASHBOARD,
      });
    },
  },
};
</script>

<style scoped lang="scss" src="./index.scss" />
