<template>
  <div class="social-auth">
    <!--    <button-->
    <!--      class="social-btn social-btn&#45;&#45;facebook"-->
    <!--      @click.prevent="authProvider('facebook')"-->
    <!--    >-->
    <!--      <AppIcon componentName="FacebookIcon" />-->
    <!--      Facebook-->
    <!--    </button>-->

    <!--    <button-->
    <!--      class="social-btn social-btn&#45;&#45;linkedin"-->
    <!--      @click.prevent="authProvider('linkedin')"-->
    <!--    >-->
    <!--      <AppIcon componentName="Linkedinlcon" />-->
    <!--      LinkedIn-->
    <!--    </button>-->

    <button
      class="social-btn social-btn--google"
      @click.prevent="authProvider('google')"
    >
      <AppIcon componentName="GoogleIcon" />
      Google
    </button>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { ROUT_PATH, USER_ACCOUNT_NAMES } from "@/helpers/rout-constants";
import CompletedTemplate from "@/helpers/completed-template-hash";
import { authMixin } from "@/mixins/AuthMixin";

export default {
  mixins: [authMixin],

  computed: {
    ...mapGetters({
      isLoggedIn: "auth/isLoggedIn",
      isSubscribed: "user/isSubscribed",
    }),
  },

  data() {
    return {
      doc_hash: "",
      doc_type: "",
    };
  },

  async created() {
    let template = CompletedTemplate.get();
    if (
      template != null &&
      template.hash != null &&
      template.hash != undefined
    ) {
      this.doc_hash = template.hash;
    }
    if (
      template != null &&
      template.type != null &&
      template.type != undefined
    ) {
      this.doc_type = template.type;
    }
  },

  methods: {
    authProvider(provider) {
      const self = this;

      this.$auth
        .authenticate(provider)
        .then(async (response) => {
          await self.$store.dispatch("loader/pending");

          const socialAuthData = {
            provider,
            ...response,
          };

          await self.sendSocialData(socialAuthData);

          await self.$store.dispatch("loader/done");
        })
        .catch((err) => {
          self.$store.dispatch("loader/done");
          console.error(err);
        });
    },

    async sendSocialData(payloadFields) {
      try {
        this.pending = true;
        await this.$store.dispatch("auth/loginSocial", payloadFields);

        if (CompletedTemplate.get() || this.$route.params.planId) {
          if (this.doc_type == "resume") {
            await this.$store.dispatch("resumeCreation/bindResumeToUser");
          }
          if (this.doc_type == "letter") {
            await this.$store.dispatch("coverLetterCreation/bindLetterToUser");
          }

          if (this.isSubscribed) {
            await this.$router.push(ROUT_PATH.USER_DASHBOARD);
          } else {
            await this.$router.push(ROUT_PATH.SUBSCRIBE_STEPS.SUBSCRIBE_PLANS);
          }
        } else {
          this.$router.push(ROUT_PATH.USER_DASHBOARD);
        }
        // this.$router.push("/");
      } catch (e) {
        console.error(e);
      }

      this.pending = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.social-auth {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (min-width: map_get($grid-breakpoints, md)) {
    flex-wrap: nowrap;
  }

  ::v-deep .social-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 56px;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    border-radius: 8px;
    width: 100%;
    margin-bottom: 16px;

    @media (min-width: map_get($grid-breakpoints, md)) {
      flex: 1;
      width: auto;
      margin-bottom: 0;
      margin-right: 16px;
    }

    &:last-child {
      margin-right: 0;
    }

    svg {
      width: 20px;
      height: 20px;
      margin-right: 16px;
    }

    &--facebook {
      background-color: #3f588f;
      color: #fff;
    }

    &--linkedin {
      background-color: #4077ad;
      color: #fff;
    }

    &--google {
      border: 1px solid #d2d2d2;
    }
  }
}
</style>
